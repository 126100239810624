<template>
  <tr>
    <wi-list-data-table-actions :config="config" :props="props"></wi-list-data-table-actions>
    <td class="justify-left">
      <strong>{{props.item.name}}</strong>
    </td>
    <td class="justify-left">
      <span>{{props.item.domain}}</span>
    </td>
    <td class="justify-center">
      <div style="text-align: center;">
        <v-icon>{{props.item.status.icon}}</v-icon><br>
        <strong :style="{color: props.item.status.color}">{{props.item.status.name}}</strong>
      </div>
    </td> 
    <td class="justify-left">
      <v-btn :disabled="props.item.status.code != 'switched-on'" small outline :loading="loading" @click="siteLogin()">
        <v-icon>open_in_new</v-icon>
        <span> Acessar</span>
      </v-btn>
      <form
        target="_blank"
        :id="props.item.id"
        :action="login.url"
        :method="login.method"
      ></form>
    </td> 
    <td class="justify-left">
      <v-menu bottom left>
        <v-btn slot="activator" outline small block>
          <v-icon>settings</v-icon>
          <span>Opções</span>
        </v-btn>
        <v-list>
          <v-list-tile v-if="props.item.status.code == 'switched-on'" :loading="loading" @click="publishSite()">
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon color="blue">publish</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Publicar Site</v-list-tile-title>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile v-if="props.item.status.code == 'switched-off'" :loading="loading" @click="enableSite()">
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon color="green">power_settings_new</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Ligar Site</v-list-tile-title>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile v-if="props.item.status.code == 'switched-on'" :loading="loading" @click="disableSite()">
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon color="red">power_settings_new</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Desligar Site</v-list-tile-title>
          </v-list-tile>
          <v-divider></v-divider>
          <v-list-tile v-if="props.item.status.code != 'switched-on'" :loading="loading" @click="statusSite()">
            <v-list-tile-avatar>
              <v-avatar>
                <v-icon color="orange">autorenew</v-icon>
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-title>Atualizar status</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
    </td>
  </tr>
</template>
<script>
  import { callApi } from '@/default/service/Api'
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'Posts',
    data: () => ({
      login: {
        url: '',
        method: 'POST',
        fields: []
      },
      loading: false
    }),
    methods: {
      siteLogin: async function () {
        this.loading = true
        const login = await this.getLoginCredentials();
        this.login = JSON.parse(atob(login))
        await this.createLoginAction(login)
        this.stopLoading()
      },
      getLoginCredentials: async function () {
        return new Promise(success => {
          callApi.post({
            uri: `sites/site/login/${this.props.item.id}`,
            data: {},
            sucess: data => {
              success(data?.data?.data)
            },
            error: () => {
              success({})
            }
          })
        })
      },
      createLoginAction: async function () {
        var form = document.getElementById(this.props.item.id);
        this.login?.fields?.forEach(({ name, value }) => {
          var input = document.createElement("input");
          input.type = 'hidden'
          input.name = name
          input.value = value
          form.appendChild(input);
        })
        await new Promise(success => {
          setTimeout(() => {
            form.submit()
            form.innerHTML = ''
            success()
          }, 100);
        })
      },
      publishSite: async function () {
        this.loading = true
        await callApi.post({
            uri: `sites/site/publish/${this.props.item.id}`,
            data: {},
            sucess: this.stopLoading,
            error: this.stopLoading
          })
      },
      enableSite: async function () {
        this.loading = true
        await callApi.post({
            uri: `sites/site/enable/${this.props.item.id}`,
            data: {},
            sucess: this.stopLoading,
            error: this.stopLoading
          })
      },
      disableSite: async function () {
        this.loading = true
        await callApi.post({
            uri: `sites/site/disable/${this.props.item.id}`,
            data: {},
            sucess: this.stopLoading,
            error: this.stopLoading
          })
      },
      statusSite: async function () {
        this.loading = true
        await callApi.post({
            uri: `sites/site/status/${this.props.item.id}`,
            data: {},
            sucess: this.stopLoading,
            error: this.stopLoading
          })
      },
      stopLoading: function () {
        this.loading = false
        this.config.refresh()
      }
    },
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions
    }
  }
</script>
<style>
  td {
    padding: 0 5px !important;
  }
  th {
    padding: 0 5px !important;
  }
</style>